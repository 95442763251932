.scrolled-bg {
  opacity: 1;
  transition: border-bottom 100ms linear;
  /* background-color: rgba(31, 31, 31, 1); */
  background-color: #727cf5;
  border-bottom: 1px solid rgba(33, 29, 24, 0.1);
  box-shadow: 0px 1px 5px #646a71;
}
.original-bg {
  /* background-color: rgba(31, 31, 31, 1); */
  background-color: #727cf5;
}
.my-right {
  justify-content: right;
}
.social-media {
  font-size: larger;
}
.custom-nav-link {
  color: rgb(255, 242, 214);
  padding-bottom: 2px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 0.5em !important;
}
.logout-link {
  color: rgb(255, 242, 214);
  box-shadow: none !important;
}
.block-shadow {
  box-shadow: 0px 0px 0px black !important;
}
.active {
  /* box-shadow: 0 12px 0px -12px rgb(255, 242, 214) !important; */
  /* box-shadow: inset 0px 5px 0px -4px rgb(255, 242, 214); */
  box-shadow: inset 0em -1px rgb(255, 242, 214);
  border-radius: 5%;
  transition: box-shadow 0.2s ease-in-out !important;
}
@media (max-width: 992px) {
  .logo-centered {
    position: relative;
    left: 15%;
    transform: translateX(-50%);
  }
  .sidebar-toggle-btn {
    position: relative;
    left: 0%;
    color: rgb(191 196 250);
    border: 1px solid rgb(128 137 246);
    border-radius: 10%;
  }
}
/* .sidebar-btn-border {
  border: 1px solid rgb(128 137 246);
  border-radius: 10%;
} */
