.custom-dropdown .dropdown-menu {
  width: 320px;
}
#wishlist-dropdown {
  background-color: rgba(114, 124, 245, 0.5) !important;
  border-color: #565db8 !important;
}

.myButton:hover {
  text-decoration: underline;
  /* background-color: rgb(233, 227, 246); */
}

#my-dropdown-link:hover {
  text-decoration: underline;
}
