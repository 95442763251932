/* change hero background to gold and black theme */
.my-hero-section {
  background-size: cover;
  background-position: center;
  min-height: 81vh;
}
.my-vid-div {
  border: 1px solid;
  border-color: rgb(165, 127, 64);
  border-bottom: 1px solid rgb(165, 127, 64, 1);
}

.gold-text {
  color: rgb(255, 242, 214) !important;
}

.background-color {
  background: #727cf5;
}

/* .social-icons {
  color: rgb(255, 242, 214);
  font-size: 1em;
}
.social-item {
  padding-right: 1em;
}
.social-container {
  position: absolute;
  bottom: 2em;
} */

/* .my-center {
  background-color: gray;
  justify-content: center;
} */

/* Dark Gold: 219, 183, 120 */
/* Hero Section Gold color rgb(165,127,64 */
/* gray rgb(50,50,49) */
/* black rgb(31,31,31 */
/* light gold: rgb(255,242,214) */
