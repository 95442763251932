/* #my-footer-style {
  --bs-bg-opacity: 1 !important;
  background-color: rgba(49, 58, 70);
} */

.text-muted {
  --ct-text-opacity: 1;
  /* color: var(--ct-text-muted) !important; */
  color: rgba(255, 242, 214, 0.55) !important;
}

.my-background {
  --ct-bg-opacity: 1;
  /* background-color: rgba(31, 31, 31, 1); */
  background-color: #727cf5;
}
a {
  color: var(--ct-link-color);
  text-decoration: none;
}
